import { Flex, FormControl, FormErrorMessage, Input, Textarea } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import isEmpty from 'lodash/isEmpty';
import { IconicInteractionConfigurationT } from '../../model/Interaction.ts';
import { usePreviewPageInfo } from '../../../../pages/PreviewPage/PreviewPage.tsx';
import { saveIconicEntry } from '../../service/EntryService.ts';
import { IconPicker } from '../IconPicker';
import { InteractionWrapper } from '../InteractionWrapper';
import { InteractionContainer } from '../InteractionContainer';
import { InteractionProps } from '../Interaction/Interaction.tsx';
import { validateMaxLength, validateMinLength, validateRequired } from '../../validation/InteractionValidation.ts';
import { hasSubmission, recordSubmission } from '../../service/InteractionSubmissionTracker.ts';
import { translate } from '../../../../translate';
import { AlertBox } from '../../../commons/components/AlertBox';

interface FormValueType {
	heading: string;
	text: string;
	icon: string;
}

export const IconicInteraction: React.FC<InteractionProps> = ({ presentationId, interaction, onInteractionEnd }) => {
	const config = interaction.configuration as IconicInteractionConfigurationT;
	const required = true; //interaction.required;
	const alreadySubmitted = hasSubmission(interaction.id);

	const {
		register,
		handleSubmit,
		control,
		formState: { isSubmitting, errors },
		setFocus,
		watch,
		reset,
	} = useForm<FormValueType>({
		defaultValues: {
			heading: '',
			text: '',
			icon: '',
		},
	});

	const { shouldAutoFocus } = usePreviewPageInfo();

	useEffect(() => {
		if (shouldAutoFocus) {
			setFocus('heading');
		}
	}, [setFocus, shouldAutoFocus]);

	const onSubmit: SubmitHandler<FormValueType> = async (data) => {
		if (!isEmpty(data.heading) && !isEmpty(data.icon)) {
			await saveIconicEntry(presentationId, interaction.id, {
				text: data.text,
				heading: data.heading,
				icon: data.icon,
			});
			recordSubmission(interaction.id);
			reset();
			onInteractionEnd();
		} else if (alreadySubmitted || !required) {
			onInteractionEnd();
		}
	};

	const values = watch();
	const hasData = !isEmpty(values.icon) || !isEmpty(values.heading);
	return (
		<InteractionWrapper interaction={interaction} onSubmit={handleSubmit(onSubmit)} isSubmitting={isSubmitting}>
			<InteractionContainer>
				<Controller
					name='icon'
					control={control}
					rules={{
						validate: {
							required: (value) => {
								return (required && !alreadySubmitted) || hasData ? validateRequired(value, 'Please select an icon before submitting') : undefined;
							},
						},
					}}
					render={({ field, fieldState }) => (
						<Flex flexDirection='column' alignItems='center'>
							<IconPicker value={field.value} onIconSelected={field.onChange} error={!!fieldState.error} />
							{fieldState.error && <AlertBox title={fieldState.error?.message} my={4} />}
						</Flex>
					)}
				/>
				<FormControl isInvalid={!isEmpty(errors.heading)} my={4}>
					<Input
						size='lg'
						maxLength={500}
						placeholder={config.placeholderTitle}
						autoComplete='off'
						autoFocus={shouldAutoFocus}
						{...register('heading', {
							validate: {
								required: (value) => {
									return (required && !alreadySubmitted) || hasData ? validateRequired(value, translate('Text field is required')) : undefined;
								},
								minLength: (value) => {
									return validateMinLength(value, 3, translate('Field too short'));
								},
								maxLength: (value) => {
									return validateMaxLength(value, 500, translate('Field too long'));
								},
							},
						})}
					/>
					{errors.heading && <AlertBox title={errors.heading?.message} my={4} />}
				</FormControl>
				{config.longAnswer ? (
					<FormControl isInvalid={!isEmpty(errors.text)}>
						<Textarea
							rows={5}
							maxLength={2400}
							placeholder={config.placeholderDescription}
							{...register('text', {
								validate: {
									minLength: (value) => {
										return validateMinLength(value, 3, translate('Field too short'));
									},
									maxLength: (value) => {
										return validateMaxLength(value, 500, translate('Field too long'));
									},
								},
							})}
						/>
						{errors.text && <FormErrorMessage>{errors.text.message}</FormErrorMessage>}
					</FormControl>
				) : null}
			</InteractionContainer>
		</InteractionWrapper>
	);
};

IconicInteraction.defaultProps = {};
