export const VALIDATION_URL_REGEX = /(?:https?:\/\/)?(?:www\.)?[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(?:\/[^\s]*)?/;

export function isValidUrl(url: string) {
	try {
		const withProtocol = toValidUrl(url);
		const urlObject = new URL(withProtocol);
		return !!urlObject;
	} catch (_) {
		return false;
	}
	// return !isEmpty(url) && VALIDATION_URL_REGEX.test(url);
}

export function toValidUrl(url: string): string {
	return url.startsWith('http') || url.startsWith('https') ? url : `https://${url}`;
}
