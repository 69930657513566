import React from 'react';
import { AverageVisualization } from '../AverageVisualization';
import { RotatingWordsVisualization } from '../RotatingWordsVisualization';
import { WheelVisualization } from '../WheelVisualization';
import { NodesVisualization } from '../NodesVisualization';
import { GaugeVisualization } from '../GaugeVisualization';
import { BarChartVisualization } from '../BarChartVisualization';
import { WordCloudVisualization } from '../WordCloudVisualization';
import { PieChartVisualization } from '../PieChartVisualization';
import isEqual from 'lodash/isEqual';
import get from 'lodash/get';
import { SentenceCloudVisualization } from '../SentenceCloudVisualization';
import { RadarVisualization } from '../RadarVisualization';
import { BlocksVisualization } from '../BlocksVisualization';
import { ComicVisualization } from '../ComicVisualization';
import { PictogramVisualization } from '../PictogramVisualization';
import { VisualizationT, VisualizationTypeT } from '../../model/Visualization.ts';
import { BasicCardsVisualization } from '../BasicCardsVisualization';
import { BasicFlipCardsVisualization } from '../BasicFlipCardsVisualization';
import { SlideArrows } from '../../../presentation/components/SlideArrows';
import { BoardVisualization } from '../BoardVisualization';
import { PillsVisualization } from '../PillsVisualization';

export interface VisualizationProps {
	presentationId: string;
	visualization: VisualizationT;
}

export const Visualization: React.FC<VisualizationProps> = (props) => {
	const renderComponent = () => {
		switch (props.visualization.type) {
			case VisualizationTypeT.ROTATING_WORDS:
				return <RotatingWordsVisualization key={props.visualization.id} {...props} />;
			case VisualizationTypeT.SIMPLE_MATH:
				return <AverageVisualization key={props.visualization.id} {...props} />;
			case VisualizationTypeT.BAR_CHART:
				return <BarChartVisualization key={props.visualization.id} {...props} />;
			case VisualizationTypeT.WHEEL:
				return <WheelVisualization key={props.visualization.id} {...props} />;
			case VisualizationTypeT.BLOCKS:
				return <BlocksVisualization key={props.visualization.id} {...props} />;
			case VisualizationTypeT.GAUGE:
				return <GaugeVisualization key={props.visualization.id} {...props} />;
			case VisualizationTypeT.RADAR:
				return <RadarVisualization key={props.visualization.id} {...props} />;
			case VisualizationTypeT.PICTOGRAM:
				return <PictogramVisualization key={props.visualization.id} {...props} />;
			case VisualizationTypeT.NODES:
				return <NodesVisualization key={props.visualization.id} {...props} />;
			case VisualizationTypeT.PIE_CHART:
				return <PieChartVisualization key={props.visualization.id} {...props} />;
			case VisualizationTypeT.WORD_CLOUD:
				if (isEqual(get(props.visualization, 'configuration.variant'), 'WORD')) {
					return <WordCloudVisualization key={props.visualization.id} {...props} />;
				} else if (isEqual(get(props.visualization, 'configuration.variant'), 'SENTENCE')) {
					return <SentenceCloudVisualization key={props.visualization.id} {...props} />;
				}
				break;
			case VisualizationTypeT.COMIC:
				return <ComicVisualization key={props.visualization.id} {...props} />;
			case VisualizationTypeT.BASIC_CARDS:
				return <BasicCardsVisualization key={props.visualization.id} {...props} />;
			case VisualizationTypeT.BASIC_FLIP_CARDS:
				return <BasicFlipCardsVisualization key={props.visualization.id} {...props} />;
			case VisualizationTypeT.BOARD:
				return <BoardVisualization key={props.visualization.id} {...props} />;
			case VisualizationTypeT.PILLS:
				return <PillsVisualization key={props.visualization.id} {...props} />;
			default:
				return null;
		}
	};

	return (
		<>
			{renderComponent()}
			<SlideArrows />
		</>
	);
};
