import { InteractionProps } from '../../../Interaction/Interaction.tsx';
import { MultipleChoiceConfigurationT } from '../../../../model/Interaction.ts';
import { hasSubmission, recordSubmission } from '../../../../service/InteractionSubmissionTracker.ts';
import { Button, Circle, Flex, Icon, IconButton, Input, InputGroup, InputRightElement, useStyleConfig } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import { InteractionContainer } from '../../../InteractionContainer';
import { InteractionWrapper } from '../../../InteractionWrapper';
import { RadioCard } from '../RadioCard';
import { validateMaxLength, validateRequired } from '../../../../validation/InteractionValidation.ts';
import { QUESTION_INTERACTION_ANSWER_MAX_LENGTH } from '../../../../../../util/Constants.ts';
import { translate } from '../../../../../../translate';
import { MdAdd, MdClose } from 'react-icons/md';
import { CheckIcon } from '../RadioCard/RadioCard.tsx';
import { MultipleChoiceEntryInput, saveMultipleChoiceEntry } from '../../../../service/EntryService.ts';
import { AlertBox } from '../../../../../commons/components/AlertBox';

interface FormValueType {
	singleEntry: string;
	singleOtherEntry: string;
}

export function SingleResponse({ presentationId, interaction, onInteractionEnd }: InteractionProps) {
	const config = interaction.configuration as MultipleChoiceConfigurationT;
	const required = true; //interaction.required;
	const [otherEntryEnabled, setOtherEntryEnabled] = useState(false);
	const alreadySubmitted = hasSubmission(interaction.id);
	const styles = useStyleConfig('RadioCard');
	const {
		handleSubmit,
		register,
		formState: { isSubmitting, errors },
		watch,
		reset,
		setValue,
		setError,
	} = useForm<FormValueType>({
		defaultValues: {
			singleEntry: '',
			singleOtherEntry: '',
		},
	});

	const watchSingleOtherEntry = watch(`singleOtherEntry`);

	const handleShowOther = (show: boolean) => async () => {
		reset();
		setOtherEntryEnabled(show);
	};

	const onSubmit = async (data: FormValueType) => {
		if (data.singleEntry.length === 0 && data.singleOtherEntry.length === 0) {
			setError('singleEntry', { type: 'required' });
		}

		let entry: MultipleChoiceEntryInput | undefined = undefined;
		if (data.singleEntry.length > 0) {
			entry = {
				value: data.singleEntry,
				isOther: false,
			};
		} else if (data.singleOtherEntry.length > 0) {
			entry = {
				value: data.singleOtherEntry,
				isOther: true,
			};
		}

		if (entry) {
			await saveMultipleChoiceEntry(presentationId, interaction.id, [entry]);
			recordSubmission(interaction.id);
			reset();
			onInteractionEnd();
		} else if (alreadySubmitted || !required) {
			onInteractionEnd();
		}
	};

	return (
		<InteractionWrapper interaction={interaction} onSubmit={handleSubmit(onSubmit)} isSubmitting={isSubmitting}>
			<InteractionContainer>
				{errors.singleEntry ? <AlertBox maxWidth='34rem' title={'Pick an option'} my={4} /> : null}
				<Flex flexDirection='column' gap={4}>
					{config.options.map((option, index) => (
						<RadioCard
							key={option + index}
							option={option}
							currentOption={watch('singleEntry')}
							{...register('singleEntry', {
								onChange: () => {
									setValue('singleOtherEntry', '');
								},
								validate: {
									required: (value) => {
										const multipleOtherEntry = watch('singleOtherEntry');
										if (multipleOtherEntry.length > 0) {
											return true;
										}
										return required && !alreadySubmitted ? validateRequired(value, 'Select an option') : undefined;
									},
								},
							})}
						/>
					))}

					{otherEntryEnabled ? (
						<Flex
							id='other-entry'
							sx={{
								...styles,
								paddingTop: 2,
								paddingBottom: 1,
								paddingX: 2,
							}}
							width='100%'
							justifyContent='space-between'
						>
							<InputGroup size='lg' mb={1} flexDirection='column' width='90%'>
								<Input
									maxLength={QUESTION_INTERACTION_ANSWER_MAX_LENGTH}
									autoComplete='off'
									size='lg'
									{...register(`singleOtherEntry`, {
										onChange: () => {
											setValue('singleEntry', '');
										},
										validate: {
											maxLength: (value) => {
												return validateMaxLength(value, QUESTION_INTERACTION_ANSWER_MAX_LENGTH, translate('Field too long'));
											},
										},
									})}
								/>
								<InputRightElement width='0.5rem' px={6} onClick={handleShowOther(false)}>
									<IconButton rounded='50' aria-label='Delete' size='md' fontWeight='bold' icon={<Icon color='brand' as={MdClose} />} />
								</InputRightElement>
							</InputGroup>
							<Flex alignItems='center' marginRight='0.53rem'>
								{watchSingleOtherEntry?.length > 0 ? (
									<Circle backgroundColor='buttonColor' size='8'>
										<Icon as={CheckIcon} boxSize='3.5' color='buttonTextColor' />
									</Circle>
								) : (
									<Circle backgroundColor='cardBackgroundColor' size='8' border='borderStroke' borderColor='brand' />
								)}
							</Flex>
						</Flex>
					) : null}

					{config.allowOthers && !otherEntryEnabled && (
						<Flex alignSelf='flex-end'>
							<Button variant='submit' leftIcon={<MdAdd />} onClick={handleShowOther(true)} my={2}>
								Add other
							</Button>
						</Flex>
					)}
				</Flex>
			</InteractionContainer>
		</InteractionWrapper>
	);
}
