import { VisualizationProps } from '../Visualization/Visualization.tsx';
import { useBoardData } from '../../hooks/useBoardData.ts';
import { Loading } from '../../../commons/components/Loading';
import { VisualizationWrapper } from '../VisualizationWrapper';
import isEmpty from 'lodash/isEmpty';
import { useToken } from '@chakra-ui/react';
import { Masonry } from 'react-plock';

export function BoardVisualization({ presentationId, visualization }: VisualizationProps) {
	// const conf = visualization.configuration as BoardVisualizationT;
	const { data, isLoading } = useBoardData(presentationId, visualization.id);
	const [textColor] = useToken('color', ['textColor']);

	if (isLoading) {
		return <Loading />;
	}

	return (
		<VisualizationWrapper visualization={visualization} isNoData={!isLoading && isEmpty(data)}>
			<Masonry
				items={data || []}
				config={{
					columns: [1, 2, 3],
					media: [640, 1120, 1121],
					gap: [1, 1, 1],
				}}
				render={(category) => (
					<div id='card-wrapper' key={category.key} className='flex flex-col gap-4 rounded-xl m-2' style={{ backgroundColor: `hsl(from ${category.color} h s max(90, l))` }}>
						<div className='flex rounded-t-xl p-3 justify-center' style={{ backgroundColor: category.color }}>
							<h1 style={{ color: textColor }} className='text-2xl font-semibold'>
								{category.key}
							</h1>
						</div>
						<div id='entries-wrapper' className='grid grid-cols-1 md:grid-cols-2 flex-wrap mb-4 px-3 rounded-sm gap-4'>
							{category.entries.map((entry) => (
								<div key={entry} className='rounded-sm p-4 text-center overflow-auto break-words w-48 h-48 md:w-40 md:h-40' style={{ backgroundColor: category.color }}>
									<p className='text-xl leading-tight'>{entry}</p>
								</div>
							))}
						</div>
					</div>
				)}
			/>
		</VisualizationWrapper>
	);
}
