import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { PresentationPage } from './pages/PresentationPage';
import { SlidePage } from './pages/SlidePage';
import { PreviewPage } from './pages/PreviewPage';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { NoMatchPage } from './pages/NoMatchPage';
import { Layout } from './modules/commons/components/Layout';
import './index.css';

const router = createBrowserRouter([
	{
		path: 'presentation/:presentationId',
		element: (
			<Layout>
				<PresentationPage />
			</Layout>
		),
	},
	{
		path: 'presentation/:presentationId/slides/:currentSlide',
		element: (
			<Layout>
				<SlidePage />
			</Layout>
		),
	},
	{
		path: 'presentation/:presentationId/slides/:currentSlide/preview',
		element: (
			<Layout>
				<PreviewPage />
			</Layout>
		),
	},
	{
		path: '*',
		element: <NoMatchPage />,
	},
]);

// Create a client
const queryClient = new QueryClient();

function App() {
	return (
		<QueryClientProvider client={queryClient}>
			<RouterProvider router={router} />
			<ReactQueryDevtools initialIsOpen={false} buttonPosition='bottom-left' />
		</QueryClientProvider>
	);
}

export default App;
