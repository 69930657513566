import React from 'react';
import { useCardsData } from '../../hooks/useCardsData.ts';
import { Loading } from '../../../commons/components/Loading';
import { BorderProps, Box, Card, CardBody, CardHeader, Circle, Flex, Heading, Image, LinkBox, LinkOverlay, Text, useStyleConfig } from '@chakra-ui/react';
import isEmpty from 'lodash/isEmpty';
import { Masonry } from 'react-plock';
import { BasicCardDataMedia, CardaDataMediaType, CardData, LinkPreviewDataMedia } from '../../service/VisualizationService.ts';
import { VisualizationWrapper } from '../VisualizationWrapper';
import { VisualizationProps } from '../Visualization/Visualization.tsx';
import { BasicCardsVisualizationT } from '../../model/Visualization.ts';

export const BasicCardsVisualization: React.FC<VisualizationProps> = ({ presentationId, visualization }) => {
	const conf = visualization.configuration as BasicCardsVisualizationT;
	const styles = useStyleConfig('Card');
	const borderRadius = (styles.container as Record<string, string>)?.borderRadius;
	const { data, isLoading } = useCardsData(presentationId, visualization.id);

	if (isLoading) {
		return <Loading />;
	}

	return (
		<VisualizationWrapper visualization={visualization} isNoData={!isLoading && isEmpty(data)}>
			<Box maxHeight='70%' overflowY='auto' mx={16} mb={8}>
				<Masonry
					items={data || []}
					config={{
						columns: [1, 2, 3, conf.maxColumns],
						media: [640, 1000, 1152, 1280],
						gap: [12, 12, 12, 24],
					}}
					render={(entry) => (
						<React.Fragment key={entry.title}>
							{entry.media && entry.media.mediaType === CardaDataMediaType.LINK ? (
								<LinkPreviewCard cardData={entry} hideCardTitle={conf.hideCardTitle} borderRadius={borderRadius} />
							) : (
								<BasicCard cardData={entry} hideCardTitle={conf.hideCardTitle} borderRadius={borderRadius} />
							)}
						</React.Fragment>
					)}
				/>
			</Box>
		</VisualizationWrapper>
	);
};

interface CardProps {
	cardData: CardData;
	hideCardTitle: boolean;
	borderRadius: BorderProps['borderRadius'];
}

const BasicCard = ({ cardData, hideCardTitle, borderRadius }: CardProps) => {
	const media = cardData.media as BasicCardDataMedia | undefined;
	return (
		<Card mb={2} boxShadow='lg'>
			{media?.mediaType === CardaDataMediaType.IMAGE ? (
				<CardHeader p={0}>
					<Image src={media.value} alt={cardData.title} fit='cover' objectPosition='center' w='100%' h='100%' borderTopRadius={borderRadius} />
				</CardHeader>
			) : null}
			{media?.mediaType === CardaDataMediaType.ICON ? (
				<CardHeader>
					<Circle size={16} bgColor='highlight' mr={2}>
						<Text display='flex' fontSize='4xl'>
							{media.value}
						</Text>
					</Circle>
				</CardHeader>
			) : null}
			{media?.mediaType === CardaDataMediaType.DRAWING ? (
				<CardHeader>
					<img src={`data:image/svg+xml;base64,${btoa(decodeURIComponent(encodeURIComponent(media.value)))}`} alt='SVG Content' style={{ width: '100%', height: '100%' }} />
				</CardHeader>
			) : null}
			<CardBody whiteSpace='pre-wrap'>
				<Flex alignItems='center'>
					<Heading wordBreak='break-word' my={2} size='xs' color='cardTextColor'>
						{!hideCardTitle ? cardData.title : cardData.title.replace(/./g, '  ')}
					</Heading>
				</Flex>

				<Text fontSize='2xl'>{cardData.description}</Text>
			</CardBody>
		</Card>
	);
};

const LinkPreviewCard = ({ cardData, hideCardTitle, borderRadius }: CardProps) => {
	const media = cardData.media as LinkPreviewDataMedia;

	return (
		<LinkBox>
			<Card mb={2} boxShadow='lg'>
				{!isEmpty(media.imageUrl) ? (
					<CardHeader p={0}>
						<Image src={media.imageUrl} alt={cardData.title} fit='cover' objectPosition='center' w='100%' h='100%' borderTopRadius={borderRadius} />
					</CardHeader>
				) : null}

				<CardBody whiteSpace='pre-wrap'>
					<Flex alignItems='center'>
						<LinkOverlay href={ensureProtocol(media.link)} isExternal={media.openInNewTab} rel='noreferrer noopener'>
							{!hideCardTitle && (
								<Heading wordBreak='break-word' my={2} size='xs' color='cardTextColor'>
									{cardData.title}
								</Heading>
							)}
						</LinkOverlay>
					</Flex>
					<Text fontSize='2xl'>{cardData.description}</Text>
				</CardBody>
			</Card>
		</LinkBox>
	);
};

function ensureProtocol(url: string): string {
	// Check if the URL starts with 'http://' or 'https://'
	if (!url.startsWith('http://') && !url.startsWith('https://')) {
		// Prepend '//' to the URL if it does not start with any of the above
		return '//' + url;
	}
	// Return the URL as is if it already contains a protocol
	return url;
}
