import React, { useMemo } from 'react';
import { Box, Card, CardBody, Heading, IconButton, Image, Text } from '@chakra-ui/react';
import isEmpty from 'lodash/isEmpty';
import { LinkPreview } from '../../../../service/LinkPreviewService.ts';
import { IoMdCloseCircleOutline } from 'react-icons/io';

interface Props {
	title?: string;
	description?: string;
	imageUrl?: string;
	preview?: LinkPreview;
	hideImage: boolean;
	onRemoveImage: () => void;
}

export const LinkInteractionPreview: React.FC<Props> = ({ title, description, preview, hideImage, onRemoveImage }) => {
	const actualPreview: LinkPreview | undefined = useMemo(() => {
		if (!preview) return undefined; // Handle cases when linkPreview is undefined or null

		const updatedPreview = { ...preview };

		// Replace title if heading is provided and not empty
		if (!isEmpty(title)) {
			updatedPreview.title = title!;
		}

		// Replace description if text is provided and not empty
		if (!isEmpty(description)) {
			updatedPreview.description = description!;
		}

		return updatedPreview;
	}, [title, description, preview]);

	if (actualPreview) {
		return (
			<Card maxW='xl' direction='row' alignItems='center'>
				{!hideImage && !isEmpty(actualPreview.images) && (
					<Box position='relative' mr={4}>
						<Image src={actualPreview.images[0].url} alt={actualPreview.title} borderRadius='lg' boxSize='md' objectFit='contain' maxW='200px' maxH='200px' />
						<IconButton icon={<IoMdCloseCircleOutline size={30} />} size='sm' position='absolute' top='2' right='2' aria-label='Remove image' onClick={onRemoveImage} />
					</Box>
				)}
				<CardBody py={0}>
					<Heading as='h6' my={0} size='xs' wordBreak='break-word'>
						{actualPreview.title}
					</Heading>
					<Text wordBreak='break-word'>{actualPreview.description}</Text>
				</CardBody>
			</Card>
		);
	}
};
