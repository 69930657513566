import React, { useState } from 'react';
import { InteractionContainer } from '../InteractionContainer';
import { Flex, FormControl, FormErrorMessage, Icon, Input, InputGroup, InputLeftElement, Textarea } from '@chakra-ui/react';
import isEmpty from 'lodash/isEmpty';
import { SubmitHandler, useForm } from 'react-hook-form';
import { InteractionWrapper } from '../InteractionWrapper';
import { LinkEntry, saveLinkEntry } from '../../service/EntryService.ts';
import { usePreviewPageInfo } from '../../../../pages/PreviewPage/PreviewPage.tsx';
import { LinkInteractionPreview } from './_/LinkInteractionPreview';
import { isValidUrl, toValidUrl } from '../../../commons/util/UrlValidationUtils.ts';
import { useLinkPreview } from '../../hooks/useLinkPreview.ts';
import { Loading } from '../../../commons/components/Loading';
import { MdLink } from 'react-icons/md';
import { InteractionProps } from '../Interaction/Interaction.tsx';
import { translate } from '../../../../translate';
import { validateMaxLength, validateMinLength, validateRequired } from '../../validation/InteractionValidation.ts';
import { hasSubmission, recordSubmission } from '../../service/InteractionSubmissionTracker.ts';
import { useDebounce } from '../../../../hooks/useDebounce.ts';
import { LinkInteractionConfigurationT } from '../../model/Interaction.ts';
import { AlertBox } from '../../../commons/components/AlertBox';

export const LinkInteraction: React.FC<InteractionProps> = ({ presentationId, interaction, onInteractionEnd }) => {
	const config = interaction.configuration as LinkInteractionConfigurationT;
	const required = true; //interaction.required;
	const alreadySubmitted = hasSubmission(interaction.id);
	const [customTitle, setCustomTitle] = useState('');
	const [customDescription, setCustomDescription] = useState('');
	const [hideImage, setHideImage] = useState(false);

	const {
		register,
		handleSubmit,
		watch,
		setValue,
		getValues,
		formState: { isSubmitting, errors },
		reset,
	} = useForm<LinkEntry>({
		defaultValues: {
			heading: '',
			text: '',
			link: '',
			imageUrl: '',
		},
	});
	const { shouldAutoFocus } = usePreviewPageInfo();

	const link = watch('link');
	const debounceUrl = useDebounce(link);
	const { isFetching, data: linkPreview } = useLinkPreview(isValidUrl(debounceUrl) ? debounceUrl : '');

	if (linkPreview && !hideImage && !isEmpty(linkPreview.images)) {
		setValue('imageUrl', linkPreview.images[0].url);
	}

	if (linkPreview && isEmpty(customTitle)) {
		setValue('heading', linkPreview.title);
	}
	if (linkPreview && isEmpty(customDescription)) {
		setValue('text', linkPreview.description);
	}

	const onSubmit: SubmitHandler<LinkEntry> = async (data) => {
		if (!isEmpty(data.heading) && !isEmpty(data.link)) {
			await saveLinkEntry(presentationId, interaction.id, { ...data, link: toValidUrl(data.link) });
			reset();
			setCustomTitle('');
			setCustomDescription('');
			recordSubmission(interaction.id);
			setHideImage(false);
			onInteractionEnd();
		} else if (alreadySubmitted || !required) {
			onInteractionEnd();
		}
	};

	const onRemoveImage = () => {
		setHideImage(true);
		setValue('imageUrl', '');
	};

	const values = getValues();
	const hasData = !isEmpty(values.heading) || !isEmpty(values.text) || !isEmpty(values.link);
	return (
		<InteractionWrapper interaction={interaction} onSubmit={handleSubmit(onSubmit)} isSubmitting={isSubmitting}>
			<InteractionContainer>
				<Flex flexDirection='column' alignItems='center'>
					{errors.link?.type === 'required' && <AlertBox title={errors.link.message} my={4} />}
					<FormControl isInvalid={!isEmpty(errors.link)}>
						<InputGroup size='sm'>
							<InputLeftElement pointerEvents='none' left='0.75rem' top='1rem'>
								<Icon as={MdLink} color='brand' boxSize='8' />
							</InputLeftElement>
							<Input
								size='lg'
								my={2}
								// required={required}
								autoFocus={shouldAutoFocus}
								// pattern={`${VALIDATION_URL_REGEX}`}
								autoComplete='off'
								placeholder={config.placeholder}
								{...register('link', {
									validate: {
										required: (value) => {
											return (required && !alreadySubmitted) || hasData ? validateRequired(value, translate('Enter a link')) : undefined;
										},
										validUrl: (v) => (!isValidUrl(v) ? 'invalid url' : undefined),
									},
								})}
							/>
						</InputGroup>
						{/*{errors.link?.type === 'required' && <FormErrorMessage>Link is required.</FormErrorMessage>}*/}
						{errors.link?.type === 'pattern' && <FormErrorMessage>Provide a url link</FormErrorMessage>}
					</FormControl>
					<FormControl isInvalid={!isEmpty(errors.heading)} display={isEmpty(linkPreview) ? 'none' : 'block'}>
						<Input
							size='lg'
							my={2}
							// required={required}
							maxLength={500}
							autoComplete='off'
							{...register('heading', {
								validate: {
									required: (value) => {
										return (required && !alreadySubmitted) || hasData ? validateRequired(value, translate('Text field is required')) : undefined;
									},
									minLength: (value) => {
										return validateMinLength(value, 3, translate('Field too short'));
									},
									maxLength: (value) => {
										return validateMaxLength(value, 500, translate('Field too long'));
									},
								},
								onChange: (e) => setCustomTitle(e.target.value),
							})}
						/>
						{errors.heading && <FormErrorMessage>{errors.heading.message}</FormErrorMessage>}
					</FormControl>
					<FormControl isInvalid={!isEmpty(errors.text)} display={isEmpty(linkPreview) ? 'none' : 'block'}>
						<Textarea
							rows={5}
							my={2}
							autoComplete='off'
							maxLength={2400}
							required={false}
							{...register('text', {
								validate: {
									maxLength: (value) => {
										return validateMaxLength(value, 500, translate('Field too long'));
									},
								},
								onChange: (e) => setCustomDescription(e.target.value),
							})}
						/>
						{errors.text && <FormErrorMessage>{errors.text.message}</FormErrorMessage>}
					</FormControl>
					<input hidden {...register('imageUrl')} />
					<Flex width='80%' justifyContent='center' my={4}>
						{isFetching ? (
							<Loading />
						) : (
							<LinkInteractionPreview title={customTitle} description={customDescription} preview={linkPreview} hideImage={hideImage} onRemoveImage={onRemoveImage} />
						)}
					</Flex>
				</Flex>
			</InteractionContainer>
		</InteractionWrapper>
	);
};
