import { Flex, Heading } from '@chakra-ui/react';
import isEmpty from 'lodash/isEmpty';
import React from 'react';
import { useSliderControls } from '../../../presentation/components/SliderControlsProvider/SliderControlsProvider.tsx';

interface Props {
	title: string;
	description?: string;
	titleSizes?: string[];
	subtitleSizes?: string[];
}

export const Title: React.FC<Props> = ({ title, description }) => {
	const { currentSlide } = useSliderControls();
	const shoudRenderNull = currentSlide?.hideTitle && isEmpty(description);

	if (shoudRenderNull) {
		return null;
	}

	return (
		<Flex flexDirection='column' id='title-subtitle' my={2} /* backdropFilter='blur(2px)'*/ borderRadius='3xl' padding={4}>
			{!currentSlide?.hideTitle ? (
				<Heading as='h1' fontSize={['xl', null, '2xl']} lineHeight={1.5} textAlign='center' wordBreak='break-word' my={2}>
					{title}
				</Heading>
			) : null}
			{!isEmpty(description) ? (
				<Heading as='h2' fontSize={['xl', null, 'xl']} fontWeight='medium' lineHeight={1.5} my={0} mb={2} textAlign='center' wordBreak='break-word'>
					{description}
				</Heading>
			) : null}
		</Flex>
	);
};
